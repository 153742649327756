/* eslint-disable no-underscore-dangle, no-console */
import { Form } from '@ant-design/compatible'
import { HomeTwoTone, LockOutlined, UserOutlined } from '@ant-design/icons'
import { Auth } from '@aws-amplify/auth'
import { Button, Input } from 'antd'
import { SignIn } from 'aws-amplify-react'
import CenteredLayout from 'components/layouts/CenteredLayout'
import { trackEvent } from 'helpers/posthog'
import { isEmpty } from 'lodash'
import styled from 'styled-components'
import styles, { FlexCol, FlexRow } from 'styles'

const StyledForm = styled(Form)`
  width: 320px;
  margin-top: 16px;
  .ant-form-item,
  .ant-legacy-form-item {
    margin-bottom: 10px !important;
    &.tight {
      margin-bottom: 0px !important;
    }
  }
  .c-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .c-button {
    margin-top: 30px;
    .reset-button {
      width: 100%;
    }
  }
  .c-error {
    color: ${styles.colors.error};
    text-align: center;
  }
`

const ChangeWorkspace = styled(FlexRow)`
  margin-top: 10px;
  .anticon {
    font-weight: bold;
    color: ${styles.colors.silver};
    font-size: 13px;
  }
  .name {
    font-weight: bold;
    margin-left: 6px;
  }
`

const ContactUsMessage = styled.div`
  margin: 20px auto -5px;
  text-align: center;
`

const trim = (str = '') => str.trim()
const lc = (str = '') => (str ? str.toLowerCase() : '')

class SignInComponent extends SignIn {
  constructor(props) {
    super(props)
    this._validAuthStates = ['signIn']
    this.state = { error: null, loading: false, loadingValidation: false }
  }

  onSignIn = (e) => {
    e && e.preventDefault()
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        values.userName = trim(lc(values.userName))
        values.password = trim(values.password)
        this.setState({ loading: true })
        try {
          trackEvent('Login Attempt', { username: values.userName, workspace: global.workspace.name })
          const user = await Auth.signIn(values.userName, values.password, global.workspace.meta)
          // const user = await Auth.signIn(values.userName, values.password)
          // await global.stores.auth._signIn(user)

          // User not confirmed yet - goto confirmSignIn
          // if (user.challengeName === 'SMS_MFA' || user.challengeName === 'SOFTWARE_TOKEN_MFA') {
          //   console.log('user not confirmed: ' + user.challengeName)
          //   this.changeState('confirmSignIn', user)

          // User is required to change their password - goto requireNewPassword
          // } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {

          if (user.challengeName) {
            global.data.audit.log('signIn_challenge', 'auth', {
              workspace: global.workspace.name,
              challenge: user.challengeName,
              user: values.userName,
            })
          }

          if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            console.log('require new password:', user.challengeParam)
            this.changeState('requireNewPassword', user)

            // MFA setup not complete - goto TOTPSetup
            // } else if (user.challengeName === 'MFA_SETUP') {
            //   console.log('mfa setup incomplete: ', user.challengeParam)
            //   this.changeState('TOTPSetup', user)

            // Custom challenge has been actioned - goto customConfirmSignIn
            // } else if (user.challengeName === 'CUSTOM_CHALLENGE' && user.challengeParam && user.challengeParam.trigger === 'true') {
            //   console.log('custom challenge', user.challengeParam)
            //   this.changeState('customConfirmSignIn', user)
          } else {
            this.checkContact(user)
          }
        } catch (err) {
          // SignUp verification not complete - goto confirmSignUp
          if (err.code === 'UserNotConfirmedException') {
            this.changeState('confirmSignUp', { username: values.userName })

            global.data.audit.log('signIn_error', 'auth', {
              workspace: global.workspace.name,
              user: values.userName,
              code: err.code,
            })

            // Password has been reset - goto forgotPassword
          } else if (err.code === 'PasswordResetRequiredException') {
            this.changeState('forgotPassword', { username: values.userName })

            global.data.audit.log('signIn_error', 'auth', {
              workspace: global.workspace.name,
              user: values.userName,
              code: err.code,
            })

            // some other error - show it
          } else {
            this.error(err)
          }
        } finally {
          this.setState({ loading: false })
        }
      }
    })
  }

  checkContact = (user) => {
    this.setState({ loadingValidation: true })
    Auth.verifiedContact(user).then(async (data) => {
      this.setState({ loadingValidation: false })
      // if verified - got signedIn
      if (!isEmpty(data.verified)) {
        try {
          const acceptedEula = await global.stores.auth._signIn(user)
          const state = acceptedEula ? 'signedIn' : 'acceptEula'
          this.changeState(state, user)
        } catch (err) {
          this.error(err)
        }

        // Not yet verified - goto verifyContact
      } else {
        user = Object.assign(user, data)
        this.changeState('verifyContact', user)
      }
    })
  }

  renderSignIn = () => {
    const { props } = this
    return (
      <StyledForm onSubmit={this.onSignIn}>
        <Form.Item>
          {props.form.getFieldDecorator('userName', {
            rules: [{ required: true, message: 'Enter email!' }],
          })(<Input prefix={<UserOutlined />} placeholder="Email" size="large" autoFocus />)}
        </Form.Item>

        <Form.Item className="tight">
          {props.form.getFieldDecorator('password', {
            rules: [{ required: true, message: 'Enter password!' }],
          })(<Input prefix={<LockOutlined />} placeholder="Password" type="password" size="large" />)}
        </Form.Item>

        <div className="c-controls">
          <Form.Item className="c-forgot">
            {/* <div className="signup-link" onClick={e => props.onStateChange('signUp', {})}>Create account</div> */}
            <CenteredLayout.Link id="change-workspace-link" onClick={(e) => props.onStateChange('enterWorkspace', {})}>
              Change workspace
            </CenteredLayout.Link>
          </Form.Item>

          <Form.Item className="c-forgot">
            <CenteredLayout.Link id="forgot-password-link" onClick={(e) => props.onStateChange('forgotPassword', {})}>
              Forgot Password?
            </CenteredLayout.Link>
          </Form.Item>
        </div>

        {this.state.error && <div className="c-error">{this.state.error}</div>}

        {/* hidden submit button */}
        <button type="submit" style={{ position: 'absolute', left: -9999 }} />
      </StyledForm>
    )
  }

  showComponent() {
    return (
      <CenteredLayout
        title="Pulse360 Login"
        footer={
          <FlexCol style={{ width: '100%' }} align="center">
            <Button
              onClick={this.onSignIn}
              style={{ width: '100%', marginTop: 20 }}
              loading={this.state.loading}
              className="c-reset-button"
              type="primary"
              size="large"
            >
              <span>{this.state.loading || this.state.loadingValidation ? 'Signing in..' : 'Sign in'}</span>
            </Button>
            <ChangeWorkspace justify="center">
              <HomeTwoTone />
              <div className="name">{global.workspace.name}</div>
            </ChangeWorkspace>
            <ContactUsMessage>
              Having trouble signing in? <a href="mailto:support@pulse360.com">Contact us!</a>
            </ContactUsMessage>
          </FlexCol>
        }
      >
        {this.renderSignIn()}
      </CenteredLayout>
    )
  }
}

export default Form.create()(SignInComponent)
