import dayjs from 'dayjs'
import { filter } from 'lodash'
import { action, makeObservable, observable, runInAction } from 'mobx'
import { msg } from 'stores/msg'
import practiceService from '../services/practice.service'

export function toBool(input) {
  return input ? Boolean(JSON.parse(input)) : false
}

class Practice {
  statuses = []
  types = []

  allMembers = [] // includes isAdmin
  members = [] // isAdmin filtered out
  id = 0
  name = ''
  type = ''
  userPool = ''
  domain = ''
  desc = ''
  phone = ''
  logoUrl = ''
  bucket = ''
  address1 = ''
  address2 = ''
  defaultCc = ''
  defaultBcc = ''
  secureFieldPrefix = ''
  city = ''
  state = ''
  country = ''
  locale = ''
  tz = ''
  zip = ''
  enabled = false
  permissions = []
  planId = null
  defaultReadReceipt = false
  createdAt = ''

  constructor() {
    makeObservable(this, {
      statuses: observable,
      types: observable,
      allMembers: observable,
      members: observable,
      id: observable,
      name: observable,
      type: observable,
      userPool: observable,
      domain: observable,
      desc: observable,
      phone: observable,
      logoUrl: observable,
      bucket: observable,
      address1: observable,
      address2: observable,
      defaultCc: observable,
      defaultBcc: observable,
      secureFieldPrefix: observable,
      city: observable,
      state: observable,
      country: observable,
      locale: observable,
      tz: observable,
      zip: observable,
      enabled: observable,
      permissions: observable,
      defaultReadReceipt: observable,
      planId: observable,
      createdAt: observable,
      updateMember: action.bound,
      setPractice: action.bound,
      setStatuses: action.bound,
      setTypes: action.bound,
      update: action.bound,
    })
  }

  /**
   *
   */
  setPractice(practice, advisor) {
    global.perms.permissions = practice.permissions

    const isAdmin = !!(advisor && advisor.isAdmin)
    const isTest = practice.domain.startsWith('test_')
    const isProd = process.env.REACT_APP_ENV !== 'dev'

    // for production practices, that are non-test accounts, filter
    // out all admin advisors, so that they don't appear in the app
    // if the logging in advisor is not an admin advisor
    if (isProd && !isTest && !isAdmin) {
      const members = { ...practice.members }
      practice.members = filter(members, (m) => !toBool(m.isAdmin))
      this.allMembers = members
    }
    Object.assign(this, practice)
    practice.tz ? dayjs.tz.setDefault(practice.tz) : dayjs.tz.setDefault()
  }

  /**
   *
   */
  setStatuses(statuses) {
    this.statuses = statuses
  }

  /**
   *
   */
  setTypes(types) {
    this.types = types
  }

  /**
   * Add a new member to the practice, if not exists
   */
  addMember = (member) => {
    const mmbr = this.getMember(member.id)
    if (!mmbr) {
      this.members.push(member)
    }
  }

  /**
   * Find a practice member
   */
  getMember = (id) => {
    return this.members.find((m) => m.id === id)
  }

  /**
   * Find a practice member
   */
  updateMember = (id, fields) => {
    const member = this.members.find((m) => m.id === id)
    if (member) {
      runInAction(() => {
        Object.keys(fields).forEach((key) => (member[key] = fields[key]))
      })
    }
    return member
  }

  /**
   * Delete an advisor from a practice
   * @param {*} advisor
   */
  async update(fields, showMsg = true) {
    try {
      const updated = await practiceService.updatePractice(this.id, { ...fields })
      if (showMsg && updated) {
        msg.success('Practice updated successfully')
      }
      runInAction(() => {
        Object.keys(fields).forEach((key) => {
          this[key] = fields[key]
        })
        fields.tz ? dayjs.tz.setDefault(fields.tz) : dayjs.tz.setDefault()
      })
    } catch (err) {
      msg.error('Ups, an error ocurred while updating the Practice')
    }
  }
}

export default Practice
