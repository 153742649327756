/* eslint-disable no-underscore-dangle */
import { Form } from '@ant-design/compatible'
import { Button } from 'antd'
import tos from 'assets/html/tos.html'
import { AuthPiece } from 'aws-amplify-react'
import CenteredLayout from 'components/layouts/CenteredLayout'
import styled from 'styled-components'
import styles from 'styles'

const StyledForm = styled(Form)`
  width: 500px;
  .c-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`

const EULA = styled.div`
  border: 1px solid ${styles.colors.border};
  background: ${styles.colors.darkWhite};
  max-height: 320px;
  line-height: 24px;
  overflow-y: auto;
  font-size: 12px;
  padding: 16px;
`

class AcceptEulaComponent extends AuthPiece {
  constructor(props) {
    super(props)
    this._validAuthStates = ['acceptEula']
    this.state = { accepted: null, loading: false }
  }

  /**
   *
   */
  onSubmit = (e) => {
    e && e.preventDefault()
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        this.setState({ loading: true })
        global.data.advisors.acceptEula().then(() => {
          global.app.fromEula = true
          this.changeState('signedIn', this.props.authData)
          this.setState({ loading: false })

          global.router.goto('/onboard')
        })
      }
    })
  }

  showComponent() {
    return (
      <CenteredLayout title="Terms of Service">
        <StyledForm onSubmit={this.onSubmit}>
          <p
            style={{ marginBottom: 16 }}
          >{`By clicking continue, you agree to abide by the terms of the Pulse360 EULA.`}</p>
          <Form.Item>
            <EULA dangerouslySetInnerHTML={{ __html: tos }} />
          </Form.Item>
          <div className="c-controls">
            <CenteredLayout.Link href={`${window.location.origin}/static/pdf/tos.pdf`} target="_blank">
              Download Licence Agreement
            </CenteredLayout.Link>
            <Button
              onClick={this.onSubmit}
              loading={this.state.loading}
              className="c-reset-button"
              style={{ marginTop: 10 }}
              type="primary"
              size="large"
            >
              {`Accept & Continue`}
            </Button>
          </div>

          {/* hidden submit button */}
          <button type="submit" style={{ position: 'absolute', left: -9999 }} />
        </StyledForm>
      </CenteredLayout>
    )
  }
}

export default Form.create()(AcceptEulaComponent)
