import dayjs from 'dayjs'
import { SmartField } from '../types'
import { SmartFieldConfig, SmartFieldDateConfig } from '../types/data'

export const dateFormatter = (smartfield: SmartField, value: unknown) => {
  const config = smartfield.config as SmartFieldConfig<SmartFieldDateConfig>

  try {
    if (dayjs.isDayjs(value)) {
      const { date } = config

      if (date) {
        value = value.format(date)
      }
    }

    if (typeof value === 'string' && dayjs(value).isValid()) {
      const { date } = config

      if (date) {
        value = dayjs(value).format(date)
      }
    }
  } catch (err) {
    console.log('Unable to format as date!')
  }

  return value
}
