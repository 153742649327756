/* eslint-disable no-underscore-dangle */
import { Form } from '@ant-design/compatible'
import { RightOutlined } from '@ant-design/icons'
import { Divider as AntDivider, Button, Input, List } from 'antd'
import { AuthPiece } from 'aws-amplify-react'
import CenteredLayout from 'components/layouts/CenteredLayout'
import { Loading } from 'components/routes/async'
import { sortBy } from 'lodash'
import { Observer } from 'mobx-react'
import React from 'react'
import styled from 'styled-components'
import styles, { FlexCol, FlexRow } from 'styles'

const StyledListItem = styled(List.Item)`
  padding-right: 16px;
  cursor: pointer;
  .ant-list-item-meta-title {
    color: black !important;
    font-size: 16px;
    &:hover {
      color: ${styles.colors.blue}!important;
    }
  }
`

const NewWorkspace = styled(FlexCol)`
  width: 100%;
  .ant-form,
  .ant-legacy-form {
    .ant-input {
      font-size: 18px !important;
      font-family: 'proxima-nova';
      font-weight: 600;
      text-align: center;
    }
    .ant-input-group-addon {
      color: ${styles.colors.lightBlack};
      font-size: 18px !important;
      font-family: 'proxima-nova';
      font-weight: 600;
    }
    .ant-form-item,
    .ant-legacy-form-item {
      margin-bottom: 0px !important;
    }
  }
  .forgot {
    text-align: center;
  }
`

const PreviousWorkspaces = styled(FlexCol)`
  width: 100%;
`

const Divider = styled(AntDivider)`
  .ant-divider-inner-text {
    color: ${styles.colors.lightBlack};
    font-family: 'proxima-nova';
    font-weight: 600;
    font-size: 18px !important;
  }
`

const ToastWrapper = styled.div`
  border-top: 2px solid rgb(255, 73, 73);
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
  position: fixed;
  color: #fff;
  bottom: -60px;
  left: 0px;
  right: 0px;
  height: 60px;
  top: unset;
  line-height: 60px;
  padding: 0px 30px;
  background-color: rgb(58, 71, 88);
  text-align: center;
  display: inline-block;
  font-family: 'proxima-nova';
  font-weight: 700;
  font-size: 18px;
  animation: 0.5s ease-in-out 0s 1 normal forwards running slideUp;
  overflow-y: hidden;
`

const StyledInput = styled(Input)`
  height: 40px;
  flex: 1;
  .ant-input,
  .ant-input-wrapper {
    height: 40px;
  }
`

class EnterWorkspaceComponent extends AuthPiece {
  constructor(props) {
    super(props)
    this._validAuthStates = ['enterWorkspace']
    const { teams } = global.workspace
    const sorted = sortBy(teams, ['name'])
    this.state = { teams: sorted, loading: false, error: null }
  }

  /**
   * Previously visited workspace selected handler
   */
  onSelectWorkspace = (ws) => {
    this.loadWorkspace(ws.domain)
  }

  /**
   * New workspace id inputted handler
   */
  onOpen = (e, form) => {
    e.preventDefault()
    form.validateFields(async (err, values) => {
      if (!err) {
        const domain = values.domain.replace(/\.$/, '').trim()
        this.loadWorkspace(domain)
      }
    })
  }

  /**
   * Load the given workspace
   */
  loadWorkspace = async (domain) => {
    this.setState({ loading: true })

    try {
      await global.workspace.load(domain.toLowerCase())
      this.changeState('signIn', {})
      this.setState({ error: null })
      this.error = null
    } catch (err) {
      this.setState({ error: "We couldn't find that Workspace" })
      global.workspace.validated = true
    } finally {
      this.setState({ loading: false })
    }
  }

  showComponent() {
    const style = this.state.teams.length > 6 ? { maxHeight: 283, overflow: 'auto' } : {}
    const { props } = this
    return (
      <React.Fragment>
        <Observer>
          {() => (
            <React.Fragment>
              {!global.workspace.validated ? (
                <Loading offset />
              ) : (
                <CenteredLayout title="Select your Workspace">
                  <FlexCol align="center" style={{ width: 375 }}>
                    {this.state.teams.length > 0 && (
                      <PreviousWorkspaces>
                        <CenteredLayout.P className="tight">a) Sign-in to a previous workspace.</CenteredLayout.P>
                        <List
                          style={style}
                          dataSource={this.state.teams}
                          pagination={false}
                          bordered
                          renderItem={(item) => (
                            <StyledListItem key={item.id} className="item" onClick={() => this.onSelectWorkspace(item)}>
                              <List.Item.Meta title={item.name} />
                              <RightOutlined />
                            </StyledListItem>
                          )}
                        />
                      </PreviousWorkspaces>
                    )}
                    {this.state.teams.length > 0 && <Divider>Or</Divider>}
                    <NewWorkspace>
                      <CenteredLayout.P className="tight" style={{ display: 'flex' }}>
                        {this.state.teams.length > 0 && <div style={{ marginRight: 4 }}>b)</div>}
                        Enter your Pulse360 workspace id.
                      </CenteredLayout.P>
                      <Form onSubmit={(e) => this.onOpen(e, props.form)}>
                        <FlexRow style={{ width: '100%' }}>
                          <Form.Item style={{ flex: 1 }}>
                            {props.form.getFieldDecorator('domain', {
                              rules: [
                                { required: true, message: "You'll need to provide your workspace id" },
                                {
                                  validator: (rule, domain, cb) => {
                                    const hasWhitespace = /\s/.test(domain)
                                    const msg = 'Workspace should not contain spaces'
                                    hasWhitespace ? cb(msg) : cb()
                                  },
                                },
                              ],
                            })(
                              <StyledInput
                                placeholder="your-workspace-id"
                                spellCheck="false"
                                className="input"
                                size="large"
                                autoFocus
                                rows={3}
                              />,
                            )}
                          </Form.Item>
                        </FlexRow>
                      </Form>
                      <Button
                        loading={this.state.loading}
                        style={{ width: '100%', marginTop: 16, marginBottom: 16 }}
                        onClick={(e) => this.onOpen(e, props.form)}
                        className="apply-button"
                        type="primary"
                        size="large"
                      >
                        {this.state.loading ? 'Signing-in..' : 'Sign-in'}
                      </Button>
                      {/* <Heading style={{ textAlign: 'center' }}>If you do not yet have a workspace account, speak to your practice admin, and you will be invited to join.</Heading> */}
                      <CenteredLayout.Link
                        className="forgot"
                        style={{ textAlign: 'center' }}
                        onClick={(e) => props.onStateChange('forgotWorkspace', {})}
                      >
                        Don&apos;t know your workspace id?
                      </CenteredLayout.Link>
                    </NewWorkspace>
                  </FlexCol>
                  {this.state.error && (
                    <ToastWrapper>
                      <span>{this.state.error}</span>
                    </ToastWrapper>
                  )}
                </CenteredLayout>
              )}
            </React.Fragment>
          )}
        </Observer>
      </React.Fragment>
    )
  }
}

export default Form.create()(EnterWorkspaceComponent)
