import { CloseCircleOutlined, UserOutlined } from '@ant-design/icons'
import { Button, Modal, Select } from 'antd'
import React, { useState } from 'react'
import styles, { FlexCol, FlexRow } from 'styles'

const UsersSelect = ({ options, onChange, loading }) => {
  return (
    <Select
      loading={loading}
      showSearch
      clearIcon={
        <CloseCircleOutlined style={{ fontSize: 16, marginTop: -2, marginLeft: -2, color: styles.colors.error }} />
      }
      style={{ width: 200, marginTop: 10, marginBottom: 10 }}
      onChange={(e) => onChange(e)}
      onClear={(e) => onChange(null)}
      placeholder="Select a person"
      optionFilterProp="children"
      filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
    >
      {options.map(({ id, fullName }) => (
        <Select.Option key={id} value={String(id)} title={fullName}>
          {fullName}
        </Select.Option>
      ))}
    </Select>
  )
}

const advisorsWithFullName = (replaced) =>
  global.data.advisors
    .filteredAdvisors()
    .filter(({ id, externalId }) => replaced?.id !== id && !!externalId)
    .filter(Boolean)
    .map((advisor) => ({
      ...advisor,
      fullName: global.data.advisors.getFullName(advisor),
      externalId: advisor.externalId ? advisor.externalId : null,
    }))

const SelectAdvisorReplacerModal = ({ replaced, onSubmit, onClose, action }) => {
  const [replacerCandidates] = useState(advisorsWithFullName(replaced))
  const [loading, setLoading] = useState(false)
  const [replacer, setReplacer] = useState(null)

  const disableUserAndReplace = async () => {
    setLoading(true)
    console.log(replacer)
    await onSubmit(replaced, global.data.advisors.getAdvisor(replacer))
    setLoading(false)
    onClose()
  }

  return (
    <Modal
      title={
        <FlexRow>
          <UserOutlined style={{ color: styles.colors.error, marginRight: 10, marginBottom: 2 }} />
          <h4>Task Template Reassignment</h4>
        </FlexRow>
      }
      width={500}
      open
      footer={
        <React.Fragment>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={disableUserAndReplace} danger>
            Set Replacement
          </Button>
        </React.Fragment>
      }
    >
      <FlexCol>
        <strong>
          The user you have asked to {action !== 'unlink' ? 'disable' : 'unlink'} has Tasks Templates assigned to them.
        </strong>{' '}
        <br />
        Please reassign them to an active user:
        <UsersSelect options={replacerCandidates} onChange={setReplacer} loading={loading} />
        If the active user does not exist, please update the CRM configuration first and{' '}
        {action !== 'unlink' ? 'disabling' : 'unlinking'} this user after. You can configure this by following{' '}
        <a href="settings/integrations/redtail" style={{ display: 'contents' }}>
          this link
        </a>
        .
      </FlexCol>
    </Modal>
  )
}

export default SelectAdvisorReplacerModal
