/* eslint-disable no-underscore-dangle */
import { Form } from '@ant-design/compatible'
import { UnlockOutlined } from '@ant-design/icons'
import Auth from '@aws-amplify/auth'
import { Button, Input } from 'antd'
import { ForgotPassword } from 'aws-amplify-react'
import CenteredLayout from 'components/layouts/CenteredLayout'
import { rules } from 'helpers/password'
import { isEmpty, pick } from 'lodash'
import styled from 'styled-components'
import styles from 'styles'

const StyledForm = styled(Form)`
  width: 340px;
  margin-top: 6px;
  .ant-form-item,
  .ant-legacy-form-item {
    margin-bottom: 10px !important;
    &.tight {
      margin-bottom: 0px !important;
    }
  }
  .c-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
  }
  .c-button {
    margin-top: 30px;
    .reset-button {
      width: 100%;
    }
  }
  .c-error {
    color: ${styles.colors.error};
    text-align: center;
  }
  .signup-link {
    color: ${styles.colors.blue};
    cursor: pointer;
    font-size: 14px;
    &:hover {
      text-decoration: underline;
    }
  }
`

const trim = (str = '') => str.trim()

class RequireNewPasswordComponent extends ForgotPassword {
  constructor(props) {
    super(props)
    this._validAuthStates = ['requireNewPassword']
    this.state = { delivery: null, error: null, loading: false }
  }

  checkContact = (user) => {
    this.setState({ loading: true })
    Auth.verifiedContact(user).then(async (data) => {
      this.setState({ loading: false })

      // if verified - got signedIn
      if (!isEmpty(data.verified)) {
        try {
          const acceptedEula = await global.stores.auth._signIn(user)
          const state = acceptedEula ? 'signedIn' : 'acceptEula'
          this.changeState(state, user)
        } catch (err) {
          this.error(err)
        }

        // Not yet verified - goto verifyContact
      } else {
        user = Object.assign(user, data)
        this.changeState('verifyContact', user)
      }
    })
  }

  onChange = (e) => {
    e && e.preventDefault()
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        this.setState({ loading: true })
        const user = this.props.authData
        const { requiredAttributes } = user.challengeParam
        const attrs = pick(values, requiredAttributes)
        Auth.completeNewPassword(user, trim(values.newPassword), attrs)
          .then((user) => {
            global.data.audit.log('requireNewPassword', 'auth', {
              workspace: global.workspace.name,
              user: user,
              ...attrs,
            })

            this.setState({ loading: false })
            this.checkContact(user)
            // }
          })
          .catch((err) => {
            this.setState({ loading: false })
            this.error(err)
          })
      }
    })
  }

  convertToPlaceholder(str) {
    return str
      .split('_')
      .map((part) => part.charAt(0).toUpperCase() + part.substr(1).toLowerCase())
      .join(' ')
  }

  objectWithProperties(obj, keys) {
    const target = {}
    for (const key in obj) {
      if (keys.indexOf(key) === -1) {
        continue
      }
      if (!Object.prototype.hasOwnProperty.call(obj, key)) {
        continue
      }
      target[key] = obj[key]
    }
    return target
  }

  showComponent() {
    const user = this.props.authData
    const { requiredAttributes } = user.challengeParam
    const { props } = this
    return (
      <CenteredLayout title="Change Password">
        <StyledForm>
          <p>Please change your password. {global.app.passwordRequirements}</p>
          <Form.Item>
            {props.form.getFieldDecorator('newPassword', {
              rules: [
                ...rules,
                {
                  message: 'Enter new password',
                  required: true,
                },
              ],
            })(
              <Input
                addonBefore={<UnlockOutlined />}
                placeholder="New Password"
                type="password"
                size="large"
                autoFocus
              />,
            )}
          </Form.Item>

          <Form.Item>
            {props.form.getFieldDecorator('confirmPassword', {
              rules: [
                {
                  message: 'Please confirm your password',
                  required: true,
                },
                {
                  message: 'Confirmation does not match new password',
                  validator: (rule, confirmPass, cb) => {
                    const { getFieldValue } = props.form
                    const newPass = getFieldValue('newPassword')
                    const matches = newPass === confirmPass
                    matches ? cb() : cb(true)
                  },
                },
              ],
            })(<Input addonBefore={<UnlockOutlined />} placeholder="Confirm password" type="password" size="large" />)}
          </Form.Item>

          {requiredAttributes.length > 0 && <p>Please also confirm the following information:</p>}

          {requiredAttributes.map((attr, i) => (
            <Form.Item key={attr}>
              {props.form.getFieldDecorator(attr, {
                rules: [{ required: true, message: `Enter ${attr}` }],
              })(<Input placeholder={this.convertToPlaceholder(attr)} size="large" />)}
            </Form.Item>
          ))}

          <div className="c-controls">
            <CenteredLayout.Link onClick={(e) => props.onStateChange('signIn', {})}>
              Back to Sign in
            </CenteredLayout.Link>
            <Button
              style={{ width: 120 }}
              className="c-reset-button"
              loading={this.state.loading}
              onClick={this.onChange}
              type="primary"
              size="large"
            >
              Change
            </Button>
          </div>

          {this.state.error && <div className="c-error">{this.state.error}</div>}

          {/* hidden submit button */}
          <button type="submit" style={{ position: 'absolute', left: -9999 }} />
        </StyledForm>
      </CenteredLayout>
    )
  }
}

export default Form.create()(RequireNewPasswordComponent)
