import dayjs from 'dayjs'

export const pause = async (duration = 1000) => {
  await new Promise((res) => setTimeout(res, duration))
}

export function calculateIntervals(
  interval = 15,
  openingHour = 7,
  openingMinute = 0,
  closingHour = 19,
  closingMinute = 0,
) {
  const startDate = dayjs()
  startDate.set('minute', openingMinute)
  startDate.set('hour', openingHour)

  const closingLimit = dayjs()
  closingLimit.set('hour', closingHour)
  closingLimit.set('minute', closingMinute)

  const dates = []
  do {
    dates.push(dayjs(startDate))
    startDate.add(60 / (60 / interval), 'minute')
  } while (closingLimit.isSameOrAfter(startDate))

  return dates
}

export function getCurrentRoundedTime(interval = 15, roundUp = false) {
  return getRoundedTime(dayjs(), interval, roundUp)
}

export function getRoundedTime(otherTime, interval = 30, roundUp = false) {
  const roundDirection = roundUp ? 'ceil' : 'floor'
  const rounded = dayjs(otherTime)
    .minute(Math[roundDirection](dayjs().minute() / interval) * interval)
    .second(0)
    .millisecond(0)
  if (rounded.isAfter(otherTime, 'day')) {
    return rounded.subtract(interval, 'minutes')
  }
  return rounded
}

export function composeDateTime(date, time) {
  const currentTime = dayjs(time)
  return dayjs(date).hour(currentTime.hour()).minute(currentTime.minute()).second(0).millisecond(0)
}
