import Icon, { ApiOutlined, CheckCircleFilled, DisconnectOutlined } from '@ant-design/icons'
import { Alert, Button, Col, Form, List, Modal, Row, Tabs, Typography } from 'antd'
import { isEnabled } from 'constants/integrations'
import dayjs from 'dayjs'
import { FULL_DATE } from 'helpers/formats'
import * as apiService from 'lib/apollo/client'
import { isEmpty, orderBy, upperFirst } from 'lodash'
import { observer } from 'mobx-react'
import { PropTypes } from 'prop-types'
import { useEffect, useState } from 'react'
import { MdOpenInNew } from 'react-icons/md'
import { connectSalesforce } from 'stores/apollo/query/integration.gql'
import Str from 'stores/util/str.util'
import styled from 'styled-components'
import styles, { FlexCol, FlexRow } from 'styles'
import { ActivitiesConfiguration } from './activitiesConfiguration'
import ConfigureActiveIntegration from './configureIntegration'

const uf = (str = '') => {
  if (isEmpty(str)) {
    return ''
  }
  return upperFirst(str.toLowerCase())
}

const StyledCard = styled.div`
  .description {
    flex: 1;
    .markdown {
      border-right: 1px solid ${styles.colors.layoutBorder};
      padding-right: 24px;
    }
  }
  .overview {
    margin-left: 24px;
    flex: 0 0 200px;
    height: 100%;
  }

  .ant-list-item-meta-title {
    font-family: Lato !important;
    font-size: 16px !important;
  }
  .ant-list-item-action > li > div {
    font-family: Lato !important;
    font-size: 16px !important;
    color: black !important;
    cursor: default;
  }
`

const LearnMore = (props) => (
  <Button
    className="learn-more"
    onClick={() => window.open(props.learnMoreLink, '_blank')}
    icon={<Icon component={MdOpenInNew} />}
  >
    Learn More
  </Button>
)

const Connect = (props) => (
  <Button
    disabled={props.disabled}
    onClick={props.onClick}
    className="learn-more"
    type="primary"
    icon={<ApiOutlined />}
  >
    Install
  </Button>
)

const GetStarted = (props) => (
  <Button
    style={{ marginRight: 10 }}
    disabled={props.disabled}
    onClick={props.onClick}
    className="learn-more"
    type="primary"
    icon={<ApiOutlined />}
  >
    Get Started
  </Button>
)

const Disconnect = (props) => (
  <Button className="learn-more disconnect" onClick={props.onClick} danger type="primary" icon={<DisconnectOutlined />}>
    Uninstall
  </Button>
)

const onFinish = (props, form) => () => {
  form.validateFields().then((values) => {
    const { identifier } = props
    const baseUrl = Str.addUrlProtocol(props.baseUrl || values.baseUrl)
    const url = baseUrl && new URL(baseUrl)
    const credentials = {
      key1: props.key1 || values.key1,
      key2: props.key2 || values.key2,
      key3: props.key3 || values.key3,
      baseUrl: baseUrl && url.origin,
      identifier,
    }
    const integration = global.ext.getIntegration(identifier)
    integration.connect(credentials)
  })
}

const onDisconnect = (props) => () => {
  Modal.confirm({
    title: props.name,
    content: 'Are you sure you want to remove this integration?',
    cancelText: 'Cancel',
    okType: 'danger',
    okText: 'Yes, Remove!',
    onOk: () => {
      const { identifier: id } = props
      const integration = global.ext.getIntegration(id)
      integration.disconnect(integration)
    },
  })
}

const Date = styled(FlexRow)`
  font-family: 'proxima-nova';
  font-weight: 600;
  margin-top: 8px;
  font-size: 16px;
`

const Uninstall = styled(FlexCol)`
  border: 1px solid ${styles.colors.error};
  padding: 16px 24px 24px;
  p {
    margin-bottom: 0px !important;
  }
  .disconnect {
    margin-top: 24px !important;
  }
`

const StyledAlert = styled(Alert)`
  margin-bottom: 24px;
`

const getAuthCode = async (code) => {
  await apiService.mutate(connectSalesforce, {
    redirect: window.location.href,
    code,
  })
  global.router.goto('/settings/integrations/salesforce')
}

const BaseConnect = (props) => {
  const size = global.view.isLaptopDown ? 'default' : 'large'
  const [tab, setTab] = useState('overview')
  const [form] = Form.useForm()

  useEffect(() => {
    const { code } = global.router.params
    if (code) {
      getAuthCode(code)
    }
  }, [])

  return (
    <StyledCard>
      <Tabs
        tabBarExtraContent={<Date>Added: {dayjs(props.createdAt).format(FULL_DATE)}</Date>}
        animated={{ inkBar: true, tabPane: false }}
        onChange={(t) => setTab(t)}
        activeKey={tab}
        size={size}
      >
        <Tabs.TabPane tab="Overview" key="overview">
          <FlexRow align="flex-start">
            <FlexCol className="description">
              <div className="markdown medium">
                <div dangerouslySetInnerHTML={{ __html: props.desc }} />
              </div>
              <FlexRow>
                {!isEnabled(props.state) && <GetStarted onClick={() => setTab('setup')} />}
                {props.learnMoreLink && <LearnMore learnMoreLink={props.learnMoreLink} />}
              </FlexRow>
            </FlexCol>
            <div className="overview">
              <List pagination={false}>
                {props.features.map((feat) => (
                  <List.Item
                    key={feat}
                    actions={[<CheckCircleFilled style={{ color: styles.colors.green }} key={feat} />]}
                  >
                    <List.Item.Meta title={feat} />
                  </List.Item>
                ))}
              </List>
            </div>
          </FlexRow>
        </Tabs.TabPane>
        {global.perms.get('ui.app.settings.tasks') && isEnabled(props.state) && (
          <Tabs.TabPane tab="Tasks" key="configure">
            <ConfigureActiveIntegration {...props} />
          </Tabs.TabPane>
        )}

        {isEnabled(props.state) && props.identifier === 'redtail' && (
          <Tabs.TabPane tab="Activity" key="activity">
            <ActivitiesConfiguration />
          </Tabs.TabPane>
        )}

        {isEnabled(props.state) && (
          <Tabs.TabPane tab="Status" key="status">
            <List pagination={false}>
              {orderBy(global.ext.integrationSync, ['dest', 'type'], ['desc']).map((sync) => {
                const dir = sync.dest === 'to_pulse' ? 'pulled from' : 'sent to'
                const obj = upperFirst(sync.type)
                return (
                  <List.Item key={sync.status} actions={[<div key={sync.status}>{uf(sync.status)}</div>]}>
                    <List.Item.Meta
                      title={
                        <div>
                          <b>
                            {sync.syncCount} {obj}
                          </b>{' '}
                          {dir} {props.name}
                        </div>
                      }
                    />
                  </List.Item>
                )
              })}
            </List>
          </Tabs.TabPane>
        )}
        <Tabs.TabPane tab={isEnabled(props.state) ? 'Disconnect' : 'Connect'} key="setup">
          {isEnabled(props.state) ? (
            <Uninstall className="medium" style={{ marginTop: 10 }}>
              <Typography.Title level={3} style={{ color: styles.colors.error }}>
                Danger Zone
              </Typography.Title>
              <p>
                Uninstall <strong>{props.name}</strong>?
              </p>
              <p>This will remove the app and revoke access to all resources.</p>
              <Row>
                <Col>
                  <Disconnect onClick={onDisconnect(props)} />
                </Col>
              </Row>
            </Uninstall>
          ) : (
            <FlexCol>
              {!!global.ext.enabled.length && (
                <StyledAlert message={`A sync with ${global.ext.enabled[0].name} is active.`} type="error" showIcon />
              )}
              {props.renderSettings(form, onFinish(props, form))}
              <Row>
                <Col>
                  <Connect disabled={global.ext.enabled.length} onClick={onFinish(props, form)} />
                </Col>
              </Row>
            </FlexCol>
          )}
        </Tabs.TabPane>
      </Tabs>
    </StyledCard>
  )
}

BaseConnect.LearnMore = LearnMore
BaseConnect.LearnMore.propTypes = {
  learnMoreLink: PropTypes.string.isRequired,
}

BaseConnect.propTypes = {
  learnMoreLink: PropTypes.string.isRequired,
  features: PropTypes.array,
  key1: PropTypes.string,
  key2: PropTypes.string,
  key3: PropTypes.string,
  identifier: PropTypes.string,
}

BaseConnect.defaultProps = {
  features: [],
}

export default observer(BaseConnect)
