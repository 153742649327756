/* eslint-disable no-console */
import Auth from '@aws-amplify/auth'
// import { throwUncaught } from 'stores/apollo/query/test.gql'
import Amplify, { Hub } from '@aws-amplify/core'
import { captureEvent, update as updateSentry } from 'helpers/sentry'
import { decrypt } from 'helpers/signer'
import { get, isNil, pick } from 'lodash'
import { computed, makeObservable, observable } from 'mobx'
import authService from './services/auth.service'
import supportAndMarketing from './supportAndMarketing'
// import posthog from 'posthog-js'

class AwsAuth {
  user = null
  loggedIn = null
  poolId = null
  token = null
  ssoLocalHost = false
  listener = null

  get authd() {
    return !isNil(this.token)
  }

  constructor(ws) {
    makeObservable(this, {
      user: observable,
      loggedIn: observable,
      poolId: observable,
      token: observable,
      authd: computed,
    })

    this.workspace = ws
    global.bus.on(global.bus.AUTH_INIT, this.init, this)
    Hub.listen('auth', this._authChangeHandler)
  }

  /**
   * Initialise Amplify with the stored user pool details
   */
  init = async ({ ws }) => {
    const { name, domain, pool, sso } = ws
    const { awsPoolId, awsAppId } = decrypt(pool)

    this._addSessionHandler(sso)
    Amplify.configure({
      Auth: {
        authenticationFlowType: 'USER_PASSWORD_AUTH',
        userPoolWebClientId: awsAppId,
        // mandatorySignIn: true,
        userPoolId: awsPoolId,
        region: 'us-west-2',
        clientMetadata: {
          workspace: domain,
          practice: name,
        },
      },
    })

    const currentPool = global.store.getItem('poolId')
    if (awsPoolId !== currentPool) {
      global.store.setItem('poolId', awsPoolId)
      this._resetState()
    }

    // if (ws.sso) {
    //   this._refreshUser()
    // }
  }

  /* ---------- public ---------- */

  /**
   * Sign out of aws/node
   */
  signOut = async () => {
    global.store.setItem('poolId')
    this._clearSession()
    Auth.signOut()

    global.data.advisors.setMe()
    global.deeplink.reset()

    this.loggedIn = false
    this.token = null
    this.user = null
    // posthog.reset()
  }

  /**
   * Change the users password.
   * Currently disabled, as there is no 'change password' flow.
   * A user should initiate 'forgot passord flow' from login screen
   */
  changePassword = () => {
    Auth.currentAuthenticatedUser().then((user) => {
      Auth.forgotPassword(user.username).then(() => {
        this.signOut()
      })
    })
  }

  /* ---------- Private ---------- */

  /**
   * @private
   * Login with node
   */
  _signIn = async (user) => {
    const userSession = user.getSignInUserSession()
    const { jwtToken } = userSession.idToken

    this.token = jwtToken
    this.user = user

    const resp = await this._logIn()

    if (resp.ok) {
      this._sentry(resp.advisor)
      this._setInitialState(resp)
      if (this.workspace?.team && global.data.advisors.me) {
        supportAndMarketing.initializePosthog(resp, this.workspace.team.domain)
        supportAndMarketing.initializeIntercom()
      }

      const acceptedEula = resp.advisor.acceptedEula
      this.loggedIn = acceptedEula

      return acceptedEula
    } else {
      const error = get(resp, 'error.message', 'Invalid user')
      const errorCode = get(resp, 'error.error', 'invalid_user')

      if (errorCode === 'not_authorized') {
        await this.signOut()
      } else {
        this.loggedIn = false
        throw new Error(error)
      }
    }
  }

  _logIn = async () => {
    const MAX_RETRY = 3
    let retry = 0

    while (retry < MAX_RETRY) {
      try {
        const resp = await authService.login()
        if (resp.ok && (!resp.advisor || !resp.advisor.id)) {
          throw new Error(`Invalid user: ${JSON.stringify(resp.advisor)}`)
        }
        return resp
      } catch (e) {
        captureEvent({
          message: 'login',
          data: {
            user: JSON.stringify(this.user),
            token: !!this.token,
            error: e.message,
          },
        })
        retry++
        if (retry >= MAX_RETRY) {
          return { ok: false, error: e }
        }
      }
    }

    return { ok: false }
  }

  _setInitialState = (resp) => {
    const isAdmin = resp.advisor?.isAdmin || false
    global.ext.setIntegrations(resp.integrations, resp.integration_syncs)
    global.connections.setConnections(resp.connections, isAdmin ? resp.adminConnections : [])
    global.data.practice.setStatuses(resp.statuses)
    global.data.practice.setTypes(resp.types)
    global.data.practice.setPractice(resp.practice, resp.advisor)
    global.data.advisors.setMe(resp.advisor)
    global.tags.setTags(resp.tags)
  }

  /**
   * Refresh aws session to pick up cached user
   * @private
   */
  _refreshUser = () => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        console.log('session:signedIn')
        this._signIn(user)
      })
      .catch((er) => {
        console.log('session:signedOut - ' + er)
      })
  }

  /* ---------- handlers ---------- */

  /**
   * @private
   */
  _authChangeHandler = async (data) => {
    console.log('AuthState: ' + data.payload.event)
    // if (data.payload.event === 'signIn') {}
  }

  /**
   * Clear the session on page close/refresh, if Single SignOn not enabled
   */
  _addSessionHandler = (sso) => {
    const enforceSso = this.ssoLocalHost && this.isLocalHost()
    const event = !sso && !enforceSso ? 'addEventListener' : 'removeEventListener'
    window.removeEventListener('unload', this._clearSession)
    if (event === 'addEventListener') {
      window.addEventListener('unload', this._clearSession)
    }
  }

  /* ---------- private ---------- */

  /**
   * Resets the initial app state
   */
  _resetState = () => {
    global.bus && global.bus.post(global.bus.SESSION_RESET)
    // if sso not enabled, redirect to homepage
    if (!this.workspace.sso) {
      global.router.goHome()
    }
    this._sentry()
  }

  /**
   * Clears cookie based session information
   */
  _clearSession = () => {
    console.log('CognitoSession: clear')
    // eslint-disable-next-line prefer-regex-literals
    const regex = new RegExp('^CognitoIdentityServiceProvider.')
    Object.entries(localStorage)
      .map((l) => l[0])
      .filter((l) => regex.test(l))
      .map((l) => localStorage.removeItem(l))
  }

  /**
   * Update sentry scope to add user/ws details
   */
  _sentry = (advisor, practice, connections) => {
    if (this.workspace && this.workspace.team) {
      const { domain, pool } = this.workspace.team
      const { awsPoolId } = decrypt(pool)
      const user = advisor ? pick(advisor, ['name', 'nickName', 'name', 'email', 'creds']) : null
      updateSentry(domain, awsPoolId, user)
    }
  }

  isLocalHost = () => window.location.hostname !== 'localhost'
}

export default AwsAuth
