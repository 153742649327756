import dayjs from 'dayjs'
import { makeObservable, observable } from 'mobx'
import { captureExceptionSilently } from '../helpers/sentry'
import { getCurrentRoundedTime, getRoundedTime } from '../helpers/time'
import { msg } from './msg'
import taskService from './services/task.service'

class Tasks {
  workspace = ''
  _categories = []
  _priorities = []

  /**
   * Get Task Categories
   */
  findCategories = async () => {
    if (this._categories.length && this.workspace === global.workspace.name) {
      return this._categories
    }
    global.app.loading = true
    try {
      const categories = await taskService.findCrmCategories()
      this.workspace = global.workspace.name
      this._categories = categories
    } catch (error) {
      msg.error(['task category', 'fetching'])
      captureExceptionSilently(error, { message: 'findCRMCategories' })
    } finally {
      global.app.loading = false
    }

    return this._categories
  }

  /**
   * Get Task Priorities
   */
  findPriorities = async () => {
    if (this._priorities.length && this.workspace === global.workspace.name) {
      return this._priorities
    }

    global.app.loading = true
    try {
      const priorities = await taskService.findCrmPriorities()
      this.workspace = global.workspace.name
      this._priorities = priorities
    } catch (error) {
      msg.error(['task priority', 'fetching'])
      captureExceptionSilently(error, { message: 'findCRMPriorities' })
    } finally {
      global.app.loading = false
    }

    return this._priorities
  }

  /**
   * Creates a task
   * @param {*} task
   */
  addTask = async (task) => {
    global.app.loading = true
    try {
      const taskCreated = await taskService.createTask({ task })
      task.id = taskCreated.id
      global.data.appt.updateNoteLocally(task.noteId, { task: task })
      msg.success(`Task Created!`)

      return taskCreated
    } catch (error) {
      // TODO: propagate error
      msg.error(['task', 'creating'])
      captureExceptionSilently(error, { message: 'addTask', data: { task } })
    } finally {
      global.app.loading = false
    }
  }

  /**
   * Updates a task on Pulse360 and in the Provider
   * @param {*} task
   */
  updateTask = async (task) => {
    const { id, ...payload } = task
    global.app.loading = true

    try {
      await taskService.updateTask({ id, task: payload })

      global.data.appt.updateNoteLocally(task.noteId, { task: task })
      msg.success(`Task Updated!`)

      return id
    } catch (error) {
      msg.error(['task', 'updating'])
      captureExceptionSilently(error, { message: 'updateTask', data: { task } })
    } finally {
      global.app.loading = false
    }
  }

  /**
   * Delete a task on Pulse360 and optionally, on the Provider
   * @param {*} task
   * @param {*} deleteOnProvider
   */
  deleteTask = async (task, deleteOnProvider) => {
    global.app.loading = true
    try {
      await taskService.deleteTask({ id: task.id, deleteOnProvider })
      global.data.appt.updateNoteLocally(task.noteId, { task: null })
      msg.success(`Task Deleted!`)

      return task.id
    } catch (error) {
      msg.error(['task', 'deleting'])
      captureExceptionSilently(error, { message: 'deleteTask', data: { task, deleteOnProvider } })
    } finally {
      global.app.loading = false
    }
  }

  constructor() {
    makeObservable(this, {
      _categories: observable,
      _priorities: observable,
    })
  }

  parseTaskData(task, includeTimeParsing = true) {
    if (!task) {
      return task
    }
    const assignedTo = (task.assignedTo || []).map((advisor) => (isNaN(advisor) ? String(advisor.id) : advisor))
    return { ...task, assignedTo }
  }

  /**
   * Build Task Template for Microtemplates
   * @param {Note} note
   * @param {TaskTemplate} workflowTemplate
   */
  buildTaskTemplate(task, summary) {
    if (!task || !task.name) {
      return null
    }
    const endDate = 'endDate' in task ? String(Number(task.endDate)) : undefined
    const startDate = 'startDate' in task ? String(Number(task.startDate)) : undefined
    if (global.ext.enabledCRM.includes('RedTail')) {
      task.customFields.allDay = true
    }
    if (summary?.taskTemplate?.id) {
      return { id: summary.taskTemplate.id, ...task, startDate, endDate }
    }
    return { ...task, startDate, endDate }
  }

  /**
   * Build Task from a note and a taskTemplate
   * @param {Note} note
   * @param {TaskTemplate} taskTemplate
   */
  buildTask(note, taskTemplate, isEditing = false, appointment) {
    const { id: taskId, __typename, ...task } = taskTemplate || {}
    if (task && Array.isArray(task.assignedTo) && task.name) {
      if (isEditing) {
        task.id = taskId
      }

      if (note.id) {
        task.noteId = note.id
      }

      if (Array.isArray(task.assignedTo)) {
        task.assignedTo = task.assignedTo.map((advisor) => (isNaN(advisor) ? advisor.id : advisor))
      }

      if (task.linkedTo && !Array.isArray(task.linkedTo)) {
        task.linkedTo = [isNaN(task.linkedTo) ? task.linkedTo.id : task.linkedTo]
      } else if (Array.isArray(task.linkedTo)) {
        task.linkedTo = task.linkedTo.map((clientId) => (isNaN(clientId) ? clientId.id : clientId))
      } else {
        task.linkedTo = [note.clientId]
      }

      task.status = note.status

      const [startDate, endDate] = this._createTaskDates(task.startDate, task.endDate, task.customFields, appointment)
      const { startFromEvent, endFromEvent, ...customFields } = task.customFields || {}
      const result = { ...task, startDate, endDate, ...(Object.keys(customFields).length && { customFields }) }
      return result
    } else {
      return undefined
    }
  }

  /**
   * Builds the dates for a Task. The dates can be user-set, or number.
   * @param {Number | Date} startDate
   * @param {Number | Date} endDate
   * @param {Object} customFields
   * @param {Object} relatedAppointment
   */
  _createTaskDates(startDate, endDate = 0, customFields, relatedAppointment) {
    const { allDay = true, endFromEvent = false, startFromEvent = false } = customFields || {}
    const { global: globalAppointment = false, date: apptDate = new Date() } = relatedAppointment || {}

    // xDateIsNumber is the case for data that comes from the taskTemplate
    const endDateIsNumber = !isNaN(endDate) && !dayjs.isDayjs(endDate)
    const startDateExists = startDate !== null && startDate !== undefined
    const startDateIsNumber = startDateExists && !isNaN(startDate) && !dayjs.isDayjs(startDate)

    // for global appointments, ignore the endFrom and startFrom props...
    const useEvent = (endFromEvent || startFromEvent) && !globalAppointment

    if (startDateExists) {
      // if specified (depends on the CRM), use it to set the startDate

      if (startDateIsNumber) {
        // useEvent defines if we use the current date & time or the date & time of the event
        startDate = (useEvent ? dayjs(apptDate) : dayjs()).add(+startDate, 'd')
      } else {
        startDate = dayjs(startDate)
      }
      if (endDateIsNumber) {
        endDate = dayjs(startDate).add(+endDate, 'd')
      } else {
        endDate = dayjs(endDate)
      }
    } else {
      // if not (the CRM doesn't support a start date), we calculate the endDate and then copy that to the startDate
      if (endDateIsNumber) {
        // useEvent defines if we use the current date & time or the date & time of the event
        endDate = (useEvent ? dayjs(apptDate) : dayjs(getCurrentRoundedTime(30, true))).add(+endDate, 'd')
      } else {
        endDate = dayjs(endDate)
      }
      startDate = dayjs(endDate)
    }

    if ((global.ext.enabledCRM.includes('RedTail') && allDay) || global.ext.enabledCRM.includes('Salesforce')) {
      // only redtail has support for the allDay custom field, and SF uses Date, not DateTime
      startDate = getRoundedTime(dayjs(startDate).hour(12))
      endDate = getRoundedTime(dayjs(endDate).hour(12))
    }

    return [startDate.toISOString(), endDate.toISOString()]
  }
}

export default Tasks
