import { ThemeConfig } from 'antd'

// https://ant.design/theme-editor

export const theme: ThemeConfig = {
  token: {
    colorPrimary: '#16ac19',
    colorLink: '#1677ff',
    colorBgLayout: 'transparent',
    colorBorderBg: '#EAEAEA',
    fontFamily:
      'Lato, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans- serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji',
    borderRadius: 6,
  },
  components: {
    Layout: {
      headerBg: 'white',
      siderBg: 'white',
      triggerBg: 'white',
    },
  },
}
