import { Placeholder } from 'components/froala/types/mapper'
import dayjs from 'dayjs'
import { SHORT } from 'helpers/formats'
import { isEmpty, isNil } from 'lodash'
import { observe } from 'mobx'
import { msg } from 'stores/msg'
import { Client } from 'types/graphql'
import { captureExceptionSilently } from '../../../../helpers/sentry'
import MergeMap from '../_merge-map'
import { Types } from '../utils'
import BaseMapper from './base'

class ClientMapper extends BaseMapper {
  static isCustom = true
  type = Types.CLIENT
  static placeHolders: Placeholder[] = [
    { id: 'firstName', label: 'Client - First name', old: 'firstname' },
    { id: 'lastName', label: 'Client - Last name', old: 'lastname' },
    { id: 'city', label: 'Client - City' },
    { id: 'gender', label: 'Client - Gender' },
    { id: 'state', label: 'Client - State' },
    { id: 'zip', label: 'Client - Zip' },
    { id: 'homePhone', label: 'Client - Phone', old: 'phone' },
    { id: 'email', label: 'Client - Email' },
    { id: 'company', label: 'Client - Company' },
    { id: 'dob', label: 'Client - Date of birth', func: (val) => val && dayjs(val).format(SHORT) },
  ]

  get resolved() {
    // @ts-ignore
    const { client } = global.data.clients
    const { id } = this.options

    const val = client?.[id as keyof Client]
    if (typeof val === 'string') {
      return !isEmpty(val)
    }

    return !isNil(val)
  }

  /**
   * @see `components/froala/config/placeholders:ClientProps`
   * @param {*} options = {
   *  label: Required - String the label for the client placeholder
   *  id: Required - the client prop to update
   * }
   */
  constructor(field: HTMLElement, options: any, mergeMap: MergeMap) {
    super(
      field,
      {
        ...options,
        path: '/clients/details',
      },
      mergeMap,
    )

    observe(
      // @ts-ignore
      global.data.clients,
      'client',
      (c) => {
        const { id /* prop */ } = this.options
        const client: any = c.newValue

        if (client?.[id]) {
          this.value = this.mapValue({ value: client?.[id] })
          this.value = this.mapValue({ value: client?.[id] })
        }
      },
      true,
    )
  }

  /**
   * Uses any specified mapping function for this placeHolder, to transform the val.
   */

  mapValue = ({ placeHolders = ClientMapper.placeHolders, value }: { placeHolders?: Placeholder[]; value: string }) => {
    return super.mapValue({ placeHolders, value })
  }

  /**
   * Checks if this mapper matches the soon to be created mapper.
   * @param {*} options
   */
  match = (options: { id?: string; customText?: string } = {}) => {
    return this.label === options.customText && this.options.id === options.id
  }

  /**
   * Updates the client value.
   * @param {*} value
   */
  // @ts-ignore
  update = async (value) => {
    // @ts-ignore
    const { updateClient, clientId } = global.data.clients
    const { id } = this.options

    // @ts-ignore
    global.app.loading = true
    try {
      if (value) {
        await updateClient(+clientId!, { [id]: value })

        this.value = this.mapValue({ value })
        this.mergeMap?.replace(this)
        msg.success(`Client data updated successfully`)
      }
    } catch (err) {
      captureExceptionSilently(err, { message: 'updateMergeTopics', data: { clientId } })
      // @ts-ignore
      msg.error(`Ups, there was a problem while updating this client, please contact us!`)
    } finally {
      // @ts-ignore
      global.app.loading = false
    }
  }
}

export default ClientMapper
