/* eslint-disable no-underscore-dangle */
import { Form } from '@ant-design/compatible'
import { EyeInvisibleOutlined, EyeOutlined, LockOutlined, MailOutlined, UnlockOutlined } from '@ant-design/icons'
import Auth from '@aws-amplify/auth'
import { Button, Input, Tooltip } from 'antd'
import { ForgotPassword } from 'aws-amplify-react'
import CenteredLayout from 'components/layouts/CenteredLayout'
import { rules } from 'helpers/password'
import styled from 'styled-components'
import styles from 'styles'

const StyledForm = styled(Form)`
  width: 320px;
  margin-top: 6px;
  .ant-form-item,
  .ant-legacy-form-item {
    margin-bottom: 10px !important;
    &.tight {
      margin-bottom: 0px !important;
    }
  }
  .c-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;
  }
  .c-button {
    margin-top: 30px;
    .reset-button {
      width: 100%;
    }
  }
  .c-error {
    color: ${styles.colors.error};
    text-align: center;
  }
  .signup-link {
    color: ${styles.colors.blue};
    cursor: pointer;
    font-size: 14px;
    &:hover {
      text-decoration: underline;
    }
  }
`

const EyeToggle = ({ show, onClick }) => {
  const commonStyles = {
    marginLeft: 10,
    cursor: 'pointer',
    fontSize: 24,
  }
  return show ? (
    <EyeOutlined style={{ ...commonStyles, color: styles.colors.green }} onClick={onClick} />
  ) : (
    <EyeInvisibleOutlined style={{ ...commonStyles, color: styles.colors.error }} onClick={onClick} />
  )
}

const trim = (str = '') => str.trim()

class ForgotPasswordComponent extends ForgotPassword {
  constructor(props) {
    super(props)
    this._validAuthStates = ['forgotPassword']
    this.state = { loading: false, delivery: null, error: null, passVis: false }
  }

  componentDidUpdate(prevProps, prevState) {
    const { authData = {}, authState = null } = this.props || {}
    const isResetWithUsername =
      !!authData?.username && authState === 'forgotPassword' && prevProps.authState !== 'forgotPassword'
    const wasCodeSent = this.state.delivery
    if (!wasCodeSent && isResetWithUsername) {
      this.send()
    }
  }

  send = () => {
    const { authData = {} } = this.props
    const username = this.state.email || authData.username

    Auth.forgotPassword(username)
      .then((data) => {
        global.data.audit.log('forgotPassword_resendCode', 'auth', {
          workspace: global.workspace.name,
          user: username,
        })
        this.setState({ delivery: data.CodeDeliveryDetails })
      })
      .catch((err) => this.error(err))
  }

  onSend = (e) => {
    e && e.preventDefault()
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        this.setState({ loading: true })
        const username = this.state.email || this.usernameFromAuthData()
        Auth.forgotPasswordSubmit(trim(username), trim(values.code), trim(values.password))
          .then((data) => {
            global.data.audit.log('forgotPassword_submit', 'auth', {
              workspace: global.workspace.name,
              code: values.code,
              user: username,
            })
            this.changeState('signIn')
            this.setState({ delivery: null, loading: false })
          })
          .catch((err) => {
            this.setState({ loading: false })
            this.error(err)
          })
      }
    })
  }

  onSubmit = (e) => {
    e && e.preventDefault()
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        this.setState({ loading: true })
        Auth.forgotPassword(trim(values.email))
          .then((data) => {
            this.setState({ email: trim(values.email), delivery: data.CodeDeliveryDetails, loading: false })
          })
          .catch((err) => {
            this.setState({ loading: false })
            this.error(err)
          })
      }
    })
  }

  onTogglePassword = () => {
    this.setState({ passVis: !this.state.passVis })
  }

  showComponent() {
    const { authData = {} } = this.props
    const send = this.state.delivery || authData.username
    const { props } = this
    return (
      <CenteredLayout title="Reset your password">
        <StyledForm layout="vertical">
          {send ? (
            <CenteredLayout.P>Enter the code you received in your email, and enter a new password</CenteredLayout.P>
          ) : (
            <CenteredLayout.P>
              You will receive a verification code in your email to reset your password.
            </CenteredLayout.P>
          )}

          {send && (
            <Form.Item className="tight" label="Enter Your Code">
              {props.form.getFieldDecorator('code', {
                rules: [{ required: true, message: 'Enter verification code!' }],
              })(<Input prefix={<UnlockOutlined />} placeholder="Verification code" size="large" autoFocus />)}
            </Form.Item>
          )}

          {send && (
            <Form.Item className="tight" label="New Password">
              {props.form.getFieldDecorator('password', {
                rules: [
                  ...rules,
                  {
                    message: 'Please enter new password',
                    required: true,
                  },
                ],
                initialValue: '',
              })(
                <Input
                  prefix={<LockOutlined />}
                  type={this.state.passVis ? 'text' : 'password'}
                  placeholder="Your new password"
                  size="large"
                  suffix={
                    <Tooltip title="Show/Hide Password">
                      <EyeToggle onClick={this.onTogglePassword} show={!this.state.passVis} />
                    </Tooltip>
                  }
                />,
              )}
            </Form.Item>
          )}

          {send && (
            <Form.Item className="tight" label="Confirm New Password">
              {props.form.getFieldDecorator('confirm', {
                rules: [
                  {
                    message: 'Please confirm your password',
                    required: true,
                  },
                  {
                    message: 'Confirmation does not match new password',
                    validator: (rule, confirmPass, cb) => {
                      const { getFieldValue } = props.form
                      const newPass = getFieldValue('password')
                      const matches = newPass === confirmPass
                      matches ? cb() : cb(true)
                    },
                  },
                ],
              })(<Input prefix={<LockOutlined />} placeholder="Confirm new password" type="password" size="large" />)}
            </Form.Item>
          )}

          {!send && (
            <Form.Item className="tight">
              {props.form.getFieldDecorator('email', {
                rules: [{ required: true, message: 'Enter email!' }],
              })(<Input prefix={<MailOutlined />} placeholder="Email" size="large" autoFocus />)}
            </Form.Item>
          )}

          <div className="c-controls">
            {send ? (
              <CenteredLayout.Link onClick={this.send}>Resend code</CenteredLayout.Link>
            ) : (
              <CenteredLayout.Link onClick={(e) => props.onStateChange('signIn', {})}>
                Back to Sign in
              </CenteredLayout.Link>
            )}
            <Button
              style={{ width: 120 }}
              loading={this.state.loading}
              onClick={send ? this.onSend : this.onSubmit}
              className="c-reset-button"
              type="primary"
              size="large"
            >
              {send ? 'Send' : 'Submit'}
            </Button>
          </div>

          {this.state.error && <div className="c-error">{this.state.error}</div>}

          {/* hidden submit button */}
          <button type="submit" style={{ position: 'absolute', left: -9999 }} />
        </StyledForm>
      </CenteredLayout>
    )
  }
}

export default Form.create()(ForgotPasswordComponent)
