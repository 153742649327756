import * as apiService from 'lib/apollo/client'

import DataError, { IsError } from '../../helpers/dataError'
import {
  CreateTranscriptionDocument,
  CreateTranscriptionMutation,
  CreateTranscriptionMutationVariables,
  TranscriptionDocument,
  TranscriptionQuery,
  TranscriptionQueryVariables,
  TranscriptionsByEventDocument,
  TranscriptionsByEventQuery,
  TranscriptionsByEventQueryVariables,
  TriggerTranscriptionStepDocument,
  TriggerTranscriptionStepMutation,
  TriggerTranscriptionStepMutationVariables,
  UpdateTranscriptionDocument,
  UpdateTranscriptionMutation,
  UpdateTranscriptionMutationVariables,
} from '../../types/graphql'

class PulseRecorderService {
  async createTranscription(payload: CreateTranscriptionMutationVariables) {
    const { createTranscription: resp } = await apiService.mutate<
      CreateTranscriptionMutation,
      CreateTranscriptionMutationVariables
    >(CreateTranscriptionDocument, payload)

    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp
  }

  async updateTranscription(payload: UpdateTranscriptionMutationVariables) {
    const { updateTranscription: resp } = await apiService.mutate<
      UpdateTranscriptionMutation,
      UpdateTranscriptionMutationVariables
    >(UpdateTranscriptionDocument, payload)

    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp
  }

  async triggerTranscriptionStep(payload: TriggerTranscriptionStepMutationVariables) {
    const { triggerTranscriptionStep: resp } = await apiService.mutate<
      TriggerTranscriptionStepMutation,
      TriggerTranscriptionStepMutationVariables
    >(TriggerTranscriptionStepDocument, payload)

    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp
  }

  async getTranscription(id: number) {
    const { transcription: resp } = await apiService.query<TranscriptionQuery, TranscriptionQueryVariables>(
      TranscriptionDocument,
      {
        id,
      },
    )

    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp
  }

  async getTranscriptionByEventId(eventId: number) {
    const { transcriptionsByEvent: resp } = await apiService.query<
      TranscriptionsByEventQuery,
      TranscriptionsByEventQueryVariables
    >(TranscriptionsByEventDocument, {
      eventId,
    })

    if (IsError(resp)) {
      throw new DataError(resp)
    }

    return resp.transcriptions
  }
}

const pulseRecorderService = new PulseRecorderService()

export default pulseRecorderService
