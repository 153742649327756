import { ConfigProvider } from 'antd'
import enUS from 'antd/locale/en_US'
import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { GlobalStyle } from 'styles'
import { theme } from 'styles/theme'
import App2 from './App'
import Auth from './AuthWrapper'

const Index = () => {
  const { loggedIn } = global.stores.auth
  const Comp = loggedIn ? App2 : Auth

  useEffect(() => {
    global.router.initialLocation = global.router.history && global.router.history.location
  }, [])

  return (
    <ConfigProvider theme={theme} locale={enUS}>
      <React.Fragment>
        <GlobalStyle />
        <Comp />
      </React.Fragment>
    </ConfigProvider>
  )
}

export default observer(Index)
